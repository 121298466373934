interface IData {
  title: string;
  name: string;
  image: string;
}

export const data: Array<IData> = [
  {
    title: "Swim Instructor",
    name: "Brooklyn Simmons",
    image: "images/team/instructor01.avif"
  },
  {
    title: "Swim Instructor",
    name: "Darlene Robertson",
    image: "images/team/instructor02.avif"
  },
  {
    title: "Swim Instructor",
    name: "Robert Fox",
    image: "images/team/instructor03.avif"
  },
  {
    title: "Swim Instructor",
    name: "Jane Cooper",
    image: "images/team/instructor04.avif"
  }
];
