interface IData {
  title: string;
  description: string;
}

export const data: Array<IData> = [
  {
    title: "We Care",
    description:
      "We believe that everybody should be able to enjoy swimming and we are here to provide you and your loved ones with the confidence, skills and support you need to get the most out of aquatics."
  },
  {
    title: "It’s a Family Thing",
    description:
      "We provide courses for all the family, which enables us to get to know our swimmers well. We’re local and family managed. We value our our customers and seeing you progress week-on-week is what makes us love our jobs."
  },
  {
    title: "Our Courses",
    description:
      "Our courses are professionally designed. We’re accredited by the highest industry authorities. Swimming can be a really healthy and enjoyable activity, and we’re here to support you all the way."
  },
  {
    title: "Value & Fun",
    description:
      "Swimming can be expensive, so we want you to get good value from your course. You will have fun, make friends, get confident, and we will do our utmost to bring out your inner swimmer - no matter your age or ability."
  }
];
