import { memo } from "react";

import "./desktop.scss";
import "./mobile.scss";

const Programs = () => {
  const swimSyncPrograms = [
    {
      tag: "Babies",
      title: "Infant Aquatic Program",
      description:
        "Australia's first aquatic program for infants from 6 weeks of age, starting with AquaLocation. We build strong, healthy, happy babies using the benefits of an aquatic environment.",
      image: <img src="/images/program-1.avif" alt="image-1" />,
    },
    {
      tag: "Learn to Swim",
      title: "Children aged 3 to 5 years",
      description:
        "The Swim Sync pre school program caters for children who have little to no water experience through to children that are learning the technical side of all four strokes. Children in the pre school program are placed in classes according to their swimming ability.",
      image: <img src="/images/program-2.avif" alt="image-2" />,
    },
    {
      tag: "School Aged",
      title: "Children aged 5 to 12 years beginner to intermediate",
      description:
        "Australia's first aquatic program for infants from 6 weeks of age, starting with AquaLocation. We build strong, healthy, happy babies using the benefits of an aquatic environment.",
      image: <img src="/images/program-3.avif" alt="image-3" />,
    },
    {
      tag: "Advanced",
      title: "Children 5 to 12 years of age advanced",
      description:
        "Australia's first aquatic program for infants from 6 weeks of age, starting with AquaLocation. We build strong, healthy, happy babies using the benefits of an aquatic environment.",
      image: <img src="/images/program-4.avif" alt="image-4" />,
    },
  ];

  return (
    <div className="programs" id="program">
      {swimSyncPrograms.map((item, key) => (
        <div className="programs-content" key={key}>
          <div className="info">
            <div className="tag">{item.tag}</div>
            <div className="title">{item.title}</div>
            <div className="description">{item.description}</div>

            <button className="btnDetails">Details</button>
          </div>

          <div className="image">{item?.image}</div>
        </div>
      ))}
    </div>
  );
};
export default memo(Programs);
