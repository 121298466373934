import { HiCheck } from "react-icons/hi";
import { dataLeft, dataRight, images } from "./data";
import "./desktop.scss";
import "./mobile.scss";

function JoinToday() {
  return (
    <section id="joinToday" className="joinToday">
      <div className="joinToday__wrapper">
        <div className="joinToday__content">
          <div className="joinToday__info">
            <h4 className="joinToday__info--subTitle">Join Today</h4>
            <h3 className="joinToday__info--title">
              Swim Academy School Invites You to Classes
            </h3>
            <p className="joinToday__info--description">
              We are an open and friendly swimming school. Each of our little
              floats is special and unique to us. We know each of our students
              not only by name, but most of all we listen to them and try to get
              to know them well. We teach swimming, but also try to pass on
              moral principles and values.
            </p>
          </div>
          <div className="joinToday__list">
            <div className="joinToday__list--left">
              {dataLeft.map((item: string, index: number) => {
                return (
                  <div key={index} className="joinToday__list--item">
                    <div className="joinToday__list--item--icon">
                      <HiCheck size={12} />
                    </div>
                    <p>{item}</p>
                  </div>
                );
              })}
            </div>
            <div className="joinToday__list--right">
              {dataRight.map((item: string, index: number) => {
                return (
                  <div key={index} className="joinToday__list--item">
                    <div className="joinToday__list--item--icon">
                      <HiCheck size={12} />
                    </div>
                    <p>{item}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="joinToday__slide">
        {images.map((image: string, index: number) => {
          return (
            <div key={index} className="joinToday__slide__item">
              <div className="joinToday__slide__item--image">
                <img src={image} alt="joinToday" />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default JoinToday;
