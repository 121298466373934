import { memo, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import SideBarItem from "./SideBarItem";
import { HiMenu } from "react-icons/hi";

import "./desktop.scss";
import "./mobile.scss";

const Header = () => {
  const [isShowSidebar, setIsShowSidebar] = useState(false);

  const toggleCollapse = (): void => {
    setIsShowSidebar((pre) => !pre);
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const menuData = [
    {
      title: "Home",
      link: "home",
    },
    {
      title: "Swim Sync Program",
      link: "program",
    },
    {
      title: "About Us",
      link: "aboutUs",
    },
    {
      title: "News",
      link: "news",
    },
    {
      title: "Team",
      link: "team",
    },
    {
      title: "Contact",
      link: "contact",
    },
  ];

  return (
    <header className="app-header">
      <div className="app-header-wrapper">
        <HiMenu size={24} onClick={() => toggleCollapse()} />

        <img src={"/images/logo.png"} className="app-logo" alt="logo" />
        <div className="menu-wrapper">
          {menuData.map((item, index) => (
            <div
              className="menu-item"
              key={index}
              onClick={() => scrollToSection(item.link)}>
              {item.title}
            </div>
          ))}
        </div>
        <div className="sign-up-wrapper">
          <div className="text">Sign Up</div>
        </div>
      </div>

      {isShowSidebar && (
        <div className="sidebar sidebar--open">
          <div className="sidebar__menu__list">
            <div className="sidebar__menu__list__icon sidebar__menu__list__icon--header">
              <img src="/svgs/logo-sign.svg" alt="logo" />
              <HiXMark
                size={22}
                style={{ cursor: "pointer" }}
                onClick={() => toggleCollapse()}
              />
            </div>
            <div className={`sidebar__menu__list__item`}>
              {menuData.map((item, index: number) => (
                <SideBarItem
                  key={index}
                  item={item}
                  isActive={false}
                  onClick={() => scrollToSection(item.link)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default memo(Header);
