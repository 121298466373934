import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import { data } from "./data";
import "./desktop.scss";
import "./mobile.scss";

const Team = () => {
  return (
    <section id="team" className="team">
      <div className="team__wrapper">
        <div className="team__info">
          <h4 className="team__info--subTitle">Passion & Skills</h4>
          <h3 className="team__info--title">Meet the Team</h3>
          <p className="team__info--description">
            Our school currently has over a dozen qualified swimming
            instructors. We all have one thing in common: a passion for sport
            and a unique approach to children.
          </p>
        </div>

        <div className="team__members">
          {data.map((item, index: number) => {
            return (
              <div key={index} className="team__member">
                <p className="team__member__title">{item.title}</p>
                <div className="team__member__avatar">
                  <img src={item.image} alt="member" />
                </div>
                <div className="team__member__nameContainer">
                  <p>{item.name}</p>
                  <Link to={"#"}>
                    <HiArrowRight size={20} />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>

        <Link to={"#"} className="team__button">
          Meet the team
        </Link>
      </div>
    </section>
  );
};

export default Team;
