import { Link } from "react-router-dom";
import { data } from "./data";
import "./desktop.scss";
import "./mobile.scss";

function AboutUs() {
  return (
    <section id="aboutUs" className="aboutUs">
      <div
        className="aboutUs__imageWrapper"
        style={{
          backgroundImage: `url(images/aboutUs/background.avif)`
        }}
      ></div>
      <div className="aboutUs__contentWrapper">
        <div className="aboutUs__contentWrapper__top">
          <div className="aboutUs__info">
            <h4 className="aboutUs__info--subTitle">Safe and Fun</h4>
            <h3 className="aboutUs__info--title">
              What Makes Swim Academy Stand Out From the Others
            </h3>
            <p className="aboutUs__info--description">
              We teach your children to become a fish in the water with the most
              advanced swimming techniques invented by world's best swimming
              instructors. Our environment is safe and comfortable. We reward
              our trainees so that they push themselves better and harder.
            </p>
            <Link className="aboutUs__info--learnMore" to={"#"}>
              Learn more
            </Link>
          </div>
          <div className="aboutUs__images">
            <div>
              <img src="images/aboutUs/aboutUs01.avif" alt="aboutUs-01" />
            </div>
            <div>
              <img src="images/aboutUs/aboutUs02.avif" alt="aboutUs-02" />
            </div>
          </div>
        </div>
        <div className="aboutUs__contentWrapper__bottom">
          <div className="aboutUs__list">
            {data.map((item, index: number) => {
              return (
                <div key={index} className="aboutUs__list__item">
                  <p className="aboutUs__list__item--title">{item.title}</p>
                  <p className="aboutUs__list__item--description">
                    {item.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
