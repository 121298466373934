export const dataLeft: Array<string> = [
  "Modern methods",
  "15 years on the market",
  "Payment in installments"
];

export const dataRight: Array<string> = [
  "Personal development",
  "Modern equipment",
  "Introductory lesson"
];

export const images: Array<string> = [
  "images/joinToday/joinToday01.avif",
  "images/joinToday/joinToday02.avif",
  "images/joinToday/joinToday03.avif",
  "images/joinToday/joinToday04.avif",
  "images/joinToday/joinToday05.avif",
  "images/joinToday/joinToday06.avif",
  "images/joinToday/joinToday05.avif"
];
