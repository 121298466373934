import "./App.css";
import "./App.scss";
import "./AppMobile.scss";

import Header from "./components/header/Header";
import Difference from "./components/difference";
import Programs from "./components/programs";
import AboutUs from "./components/AboutUs";
import JoinToday from "./components/JoinToday";
import Team from "./components/Team";
import News from "./components/news";
import Footer from "./components/footer";

function App() {
  return (
    <div className="app">
      <Header />

      <div id="home" className="section-banner">
        <div className="home-image">
          <img src="/images/home-banner.webp" alt="banner" />
        </div>
        <div className="home-bg"></div>
        <div className="home-content">
          <div className="home-content-container">
            <div className="title">
              Get Health and Well-being With Swim Sync
            </div>
            <div className="button-join-us">
              <button>Join Us Today</button>
            </div>
          </div>
        </div>
      </div>
      <Difference />

      <Programs />
      <AboutUs />
      <JoinToday />
      <Team />

      <News />
      <Footer />
    </div>
  );
}

export default App;
