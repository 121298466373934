import { memo } from "react";

import "./desktop.scss";
import "./mobile.scss";

const Difference = () => {
  const swimSyncDifference = [
    {
      title: "Safety-Centric Approach",
      description: "We believe that stronger swimmers are safer swimmers.",
      image: <img src="/svgs/safety-centric.svg" alt="image-1" />,
    },
    {
      title: "Customized Learning Experience",
      description:
        "We offer personalized learning plans based on a child's skill level and learning pace",
      image: <img src="/svgs/customized-learning.svg" alt="image-2" />,
    },
    {
      title: "Qualified & Caring Instructors",
      description:
        "Our network of swim instructors are dedicated to nurturing and supporting students’ progress",
      image: <img src="/svgs/qualified-caring.svg" alt="image-3" />,
    },
  ];

  return (
    <div className="different-programs" id="program">
      <div className="different-programs-content">
        <div className="title">Swim Sync Difference</div>
        <div className="content">
          {swimSyncDifference.map((item, key) => (
            <div className="item" key={key}>
              {item?.image}

              <div className="title">{item.title}</div>
              <div className="description">{item.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default memo(Difference);
