import { memo } from "react";

import "./desktop.scss";
import "./mobile.scss";

const News = () => {
  const swimSyncNews = [
    {
      title: "Discover Benefits of Swimming Lessons for Kids",
      description:
        "Swimming lessons are still one of the most popular extracurricular activities that children enjoy. Parents enroll their child in swimming lessons for a variety of reasons, including safety, exercise, and social interaction.",
      image: <img src="/images/news-1.avif" alt="image-1" />,
    },
    {
      title: "How Swimming Lessons Help Kids With Stress & Anxiety",
      description:
        "Nowadays, children face so many problems both at school and at home. Swimming can serve as a healthy outlet to help keep them physically and mentally fit. Not only is it one of the few non-contact activities that children can safely participate in, but swimming is scientifically proven to help reduce stress and anxiety. In fact, research shows that swimming",
      image: <img src="/images/news-2.avif" alt="image-2" />,
    },
    {
      title: "How to Motivate Yourself to Swim?",
      description:
        "Physical activity is an important part of a healthy lifestyle. More and more people realize that practicing sports is good for their health and figure. Swimming stands out from other disciplines - it comprehensively develops a healthy, strong and athletic figure. Despite the many advantages and satisfaction of swimming training, it is often difficult to motivate yourself to go to",
      image: <img src="/images/news-3.avif" alt="image-3" />,
    },
  ];

  return (
    <div className="news_wrap" id="news">
      <div className="news">
        <div className="title_1">Latest News</div>
        <div className="title_2">Swim Sync News</div>

        <div className="news-content">
          {swimSyncNews.map((item, key) => (
            <div className="card" key={key}>
              <div className="image">{item?.image}</div>

              <div className="title">{item.title}</div>
              <div className="description">{item.description}</div>

              <button className="btnDetails">
                <img src="/svgs/arrow-right.svg" alt={"arrow-right-" + key} />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default memo(News);
