import { memo } from "react";

import "./desktop.scss";
import "./mobile.scss";

const Footer = () => {
  const swimSyncFooterLinks = [
    {
      title: "Location",
      subLinks: [
        { label: "Long Avenue 36 New Jersey, CA 96120", link: "#", icon: null },
        { label: "252-151-2053", link: "#", icon: null },
        { label: "email@swimsync.com", link: "#", icon: null },
      ],
    },
    {
      title: "Explore",
      subLinks: [
        { label: "About", link: "#", icon: null },
        { label: "Contact", link: "#", icon: null },
        { label: "Meet the team", link: "#", icon: null },
        { label: "Class for you", link: "#", icon: null },
      ],
    },
    {
      title: "Site user",
      subLinks: [
        { label: "Terms & Conditions", link: "#", icon: null },
        { label: "Privacy", link: "#", icon: null },
      ],
    },
    {
      title: "Follow",
      subLinks: [
        {
          label: "Facebook",
          link: "#",
          icon: <img src="/svgs/facebook.svg" alt="logo" />,
        },
        {
          label: "Instagram",
          link: "#",
          icon: <img src="/svgs/instagram.svg" alt="logo" />,
        },
      ],
    },
  ];

  return (
    <>
      <div className="footer" id="contact">
        <div className="title_1">Class for You</div>
        <div className="title_2">
          Need Help Figuring Out Which Class Is Right for You or You Child?
        </div>
        <button className="btnFindClass">Find a class for me</button>

        <div className="lineBreak"></div>

        <div className="footer-content">
          <div className="logo">
            <img src="/svgs/logo-text-white.svg" alt="logo" />
          </div>

          <div className="linkWrap">
            {swimSyncFooterLinks.map((item, key) => (
              <div className="wrap" key={key}>
                <div className="tittle">{item?.title}</div>

                <div className="subWrap">
                  {item?.subLinks.map((subLink, key2) => (
                    <div className="wrap" key={key2}>
                      <div className="label">
                        {subLink?.icon ? subLink?.icon : null}
                        {subLink?.label}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="copyright">Copyright © 2023. All Right Reserved</div>
    </>
  );
};
export default memo(Footer);
